function onDeviceReady() {
  return import("./main-deviceready");
}

if (window.cordova) {
  document.addEventListener("deviceready", onDeviceReady, false);
} else {
  window.FileNative = window.File;
  document.addEventListener("DOMContentLoaded", onDeviceReady, false);
}
